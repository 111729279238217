const curvePoints1 =  [
    -8,  1, -600,
    -8,  1, -150,
    -8,  1, -50, //
    50,  1,  25,
    80,  1,  50,
    85,  1,  75,
    90, 1,  100,
    90, 1,  101,
    50, 1,  125,
    0, 1,  150,
    -50, 1,  175,
    -60, 1,  200,
    -60, 1,  225,
    -50, 1,  250,
    -25, 1,  275,
    0, 1,  300,
    0, 1,  301,
    0, 1,  325,
    -10, 1,  350,
    -30, 1,  400,
]
const curvePoints2 =  [
    -8,  1, -600,
    -8,  1, -150,
    -8,  1, -50,
    50,  1,  25,
    80,  1,  50,
    85,  1,  75,
    90, 1,  100,
    90, 1,  101,
    100, 1,  125,
    110, 1,  150,
    100, 1,  175,
    80, 1,  200,
    30, 1,  225,
    -20, 1,  250,
    0, 1,  275,
    0, 1,  300,
    0, 1,  301,
    0, 1,  325,
    -10, 1,  350,
    -30, 1,  400,
]


export {curvePoints1, curvePoints2}
