const canvas = document.querySelector('canvas.webgl')
const flashBigElement = document.querySelector('.flash-big')
const flashSmallElement = document.querySelector('.flash-small')
const infoElement = document.querySelector('.location-info')
const simpleFinishElement = document.querySelector('.finish-block-simple')
const infoOutsideElement = document.querySelector('.location-outside-bg')
const timeElement = document.querySelector('.time-value')
const weightElement = document.querySelector('.weight-value')
const arrowLeft = document.querySelector('.switch-left-icon')
const arrowRight = document.querySelector('.switch-right-icon')
const navigationParentElement = document.querySelector('.navigation-bar')
const navigationContainerElement = document.querySelector('.navigation-container')
const finishButtonElement = document.querySelector('.end-button')
const finishButtonSimpleElement = document.querySelector('.finish-block-simple .end-button')
const continueButtonElement = document.querySelector('.continue-button')
const continueButtonSimpleElement = document.querySelector('.finish-block-simple .continue-button')
const soundButtonElement = document.querySelector('.sound-button')
const loaderElement = document.querySelector('.loader-container')
const playButton = document.querySelector('.play-button')
const startScreen = document.querySelector('.start-screen')
const exitButton = document.querySelector('.exit-icon')

export {
    canvas,
    flashBigElement, flashSmallElement,
    infoElement, infoOutsideElement, simpleFinishElement,
    timeElement, weightElement,
    arrowLeft, arrowRight,
    navigationParentElement, navigationContainerElement,
    continueButtonElement, continueButtonSimpleElement,
    finishButtonElement, finishButtonSimpleElement,
    soundButtonElement, playButton, loaderElement,
    exitButton, startScreen,
}
