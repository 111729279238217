import * as THREE from "three";


const M3 = new THREE.Matrix3( );
const M4 = new THREE.Matrix4( );

const ls = 1000; // length segments
const ws = 1; // width segments
const lss = ls + 1;
const wss = ws + 1;

// const tex = new THREE.TextureLoader().load('./textures/CentralMarking.png');
// const tex = new THREE.TextureLoader().load('./textures/dash5.png');
// tex.wrapS = THREE.RepeatWrapping;
// tex.repeat.set(2);
// tex.magFilter = THREE.NearestFilter;
// tex.transparent = true;

const material = [
    new THREE.MeshBasicMaterial({color: 0x14213D, side: THREE.BackSide}),
    // new THREE.MeshBasicMaterial({map:tex, side: THREE.BackSide}),
];

function roadCreating (currentRoadSetting, curvePoints, scene) {
    const pts = [];

    for (let i = 0; i < curvePoints.length; i += 3) {
        pts.push(new THREE.Vector3(curvePoints[i], curvePoints[i + 1], curvePoints[i + 2]));
    }

    const curve = new THREE.CatmullRomCurve3(pts);
    currentRoadSetting.points = curve.getPoints(ls);
    const len = curve.getLength();
    const lenList = curve.getLengths(ls);

    const faceCount = ls * ws * 2;
    const vertexCount = lss * wss;

    const indices = new Uint32Array(faceCount * 3);
    const vertices = new Float32Array(vertexCount * 3);
    const uvs = new Float32Array(vertexCount * 2);

    const g = new THREE.BufferGeometry();
    g.setIndex(new THREE.BufferAttribute(indices, 1));
    g.setAttribute('position', new THREE.BufferAttribute(vertices, 3));
    g.setAttribute('uv', new THREE.BufferAttribute(uvs, 2));

    let idxCount = 0;
    let a, b1, c1, c2;

    for (let j = 0; j < ls; j++) {

        for (let i = 0; i < ws; i++) {
            // 2 faces / segment,  3 vertex indices
            a = wss * j + i;
            b1 = wss * (j + 1) + i;		// right-bottom
            c1 = wss * (j + 1) + 1 + i;
            //  b2 = c1							// left-top
            c2 = wss * j + 1 + i;

            indices[idxCount] = a; // right-bottom
            indices[idxCount + 1] = b1;
            indices[idxCount + 2] = c1;

            indices[idxCount + 3] = a; // left-top
            indices[idxCount + 4] = c1 // = b2,
            indices[idxCount + 5] = c2;

            g.addGroup(idxCount, 6, i); // write group for multi material
            idxCount += 6;
        }
    }

    let uvIdxCount = 0;

    for (let j = 0; j < lss; j++) {
        for (let i = 0; i < wss; i++) {
            uvs[uvIdxCount] = lenList[j] / len;
            uvs[uvIdxCount + 1] = i / ws;
            uvIdxCount += 2;
        }
    }

    let x, y, z;
    let posIdx = 0; // position index

    let tangent;
    const normal = new THREE.Vector3();
    const binormal = new THREE.Vector3(0, 1, 0);


    for (let j = 0; j < lss; j++) {

        // to the points

        tangent = curve.getTangent(j / ls);
        currentRoadSetting.t.push(tangent.clone());

        normal.crossVectors(tangent, binormal);

        normal.y = 0; // to prevent lateral slope of the road

        normal.normalize();
        currentRoadSetting.n.push(normal.clone());

        binormal.crossVectors(normal, tangent); // new binormal
        currentRoadSetting.b.push(binormal.clone());

    }

    const dw = [-0.5, 0.5]; // width from the center line
    // const dw = [-0.5,0.5]; // width from the center line

    for (let j = 0; j < lss; j++) {  // length

        for (let i = 0; i < wss; i++) { // width
            x = currentRoadSetting.points[j].x + dw[i] * currentRoadSetting.n[j].x;
            y = currentRoadSetting.points[j].y;
            z = currentRoadSetting.points[j].z + dw[i] * currentRoadSetting.n[j].z;

            vertices[posIdx] = x;
            vertices[posIdx + 1] = y;
            vertices[posIdx + 2] = z;

            posIdx += 3;
        }

    }

    const roadMesh = new THREE.Mesh(g, material);
    scene.add(roadMesh);
}

export { roadCreating, ls, M3, M4 }
