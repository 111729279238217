import {
    soundButtonElement,
    playButton, exitButton,
    finishButtonElement, finishButtonSimpleElement,
} from "./getHTMLElements";

/**
 * Audio
 */
let soundIsAllowed = false

const bgSound = new Audio('./audio/bg_m.mp3')
bgSound.volume = 0.5
const boxSound = new Audio('./audio/box1.mp3')
const flashSound = new Audio('./audio/flash.mp3')
const successSound = new Audio('./audio/success.mp3')
successSound.volume = 0.2

playButton.addEventListener('click', () => clickPlayButton());
exitButton.addEventListener('click', () => clickExitButton());
finishButtonElement.addEventListener('click', () => switchSoundPlay());
finishButtonSimpleElement.addEventListener('click', () => switchSoundPlay());
soundButtonElement.addEventListener('click', () => switchSoundPlay());

function switchSoundPlay() {
    soundIsAllowed = !soundIsAllowed;
    soundButtonElement.classList.toggle('sound-on')
    if(soundIsAllowed) {
        soundPlay()
    } else {
        soundStop()
    }
}
function clickPlayButton () {
    soundIsAllowed = true;
    soundButtonElement.classList.add('sound-on')
    soundPlay();
}
function clickExitButton() {
    soundIsAllowed = false;
    soundButtonElement.classList.remove('sound-on')
    soundStop();
}
function soundPlay() {
    bgSound.play();
    boxSound.play()
    boxSound.pause()
    flashSound.play()
    flashSound.pause()
    successSound.play()
    successSound.pause()
}
function soundStop() {
    bgSound.pause();
    bgSound.currentTime = 0;
}

bgSound.addEventListener('ended', function() {
    this.currentTime = 0;
    this.play();
});

export { soundIsAllowed, boxSound, flashSound, successSound }
