import {GLTFLoader} from "three/examples/jsm/loaders/GLTFLoader";
import * as THREE from "three";
import * as SkeletonUtils from "three/examples/jsm/utils/SkeletonUtils";
import * as dat from 'lil-gui'
import gsap from "gsap";
import { loaderElement } from "./getHTMLElements";

/**
 * Models
 */

// Debug
// const gui = new dat.GUI()

let objectsAreReady = {
    car: false,
    post: false,
    cafe: false,
    factory: false,
    port: false,
    barier: false,
    camera: false,
    camera_broken: false,
    windmill: false,
    windmill_broken: false,
    sign_left: false,
    sign_right: false,
    box: false
}

const gltfLoader = new GLTFLoader()
let carMesh;
let carGroup = null;
let carMixer = null;
let carAction = null;

const locationsList = {mail: null, cafe: null, factory: null, port: null}
const windmillMixerList = {mail: null, cafe: null,port: null}

const signsList = {
    left1: { object: null, intersected: false },
    left2: { object: null, intersected: false },
    right1: { object: null, intersected: false },
    right2: { object: null, intersected: false },
}

const barier = { mixer: null, action: null }

let boxMesh;


let tickFunction;
function setTickFunction(localTick) { tickFunction = localTick }

function addModels(scene){
    gltfLoader.load('./models/truck_6.glb', (object) => {
        object.scene.rotation.y = Math.PI / 2;
        object.scene.scale.set(2, 2, 2)
        carMesh = object.scene

        carGroup = new THREE.Group();
        carGroup.add(carMesh);
        scene.add(carGroup)

        carMesh.traverse((child) => {
            if (child instanceof THREE.Mesh && child.material instanceof THREE.MeshStandardMaterial) {
                child.material.transparent = true
                child.material.needsUpdate = true
                child.material.metalness = 0
                child.material.roughness = 1
                child.material.shininess = 0
                child.material.specular = new THREE.Color(0xff0000)
                child.material.lightMapIntensity = 1
            }
        })

        //Animation
        carMixer = new THREE.AnimationMixer(object.scene)
        carAction = carMixer.clipAction(object.animations[0])

        objectsAreReady.car = true;
        checkIfAllModelsAreLoaded()
    })
    gltfLoader.load('./models/post_2.glb', (object) => {
        locationsList.mail = object.scene;
        object.scene.rotation.y = Math.PI * 0.35
        object.scene.position.set(-40, 1, -65)
        scene.add(object.scene)

        objectsAreReady.post = true;
        checkIfAllModelsAreLoaded()
    })
    gltfLoader.load('./models/cafe_2.glb', (object) => {
        locationsList.cafe = object.scene;
        object.scene.rotation.y = Math.PI * 1.6
        object.scene.position.set(110, 1, 45)
        scene.add(object.scene)

        objectsAreReady.cafe = true;
        checkIfAllModelsAreLoaded()
    })
    gltfLoader.load('./models/factory_1.glb', (object) => {
        locationsList.factory = object.scene;
        object.scene.rotation.y = Math.PI * 1.65
        object.scene.position.set(15, 1, 180)
        scene.add(object.scene)

        objectsAreReady.factory = true;
        checkIfAllModelsAreLoaded()
    })
    gltfLoader.load('./models/port_1.glb', (object) => {
        locationsList.port = object.scene;
        object.scene.rotation.y = Math.PI * 1.75
        object.scene.position.set(-67, 1, 149)
        scene.add(object.scene)

        objectsAreReady.port = true;
        checkIfAllModelsAreLoaded()
    })
    gltfLoader.load('./models/barier_2.glb', (object) => {
        object.scene.rotation.y = Math.PI * 1.35
        object.scene.position.set(-16, 1, 340)
        scene.add(object.scene)

        barier.mixer = new THREE.AnimationMixer(object.scene)
        barier.action = barier.mixer.clipAction(object.animations[1])
        barier.action.clampWhenFinished = true;
        barier.action.loop = THREE.LoopOnce;

        objectsAreReady.barier = true;
        checkIfAllModelsAreLoaded()
    })

    gltfLoader.load('./models/camera_4.glb', (object) => {
        object.scene.scale.set(2, 2, 2)
        addCamera(object.scene, 10, -30, 0)
        addCamera(object.scene, 77, 80, 1.1, true)
        addCamera(object.scene, -50, 190, 1.65)

        objectsAreReady.camera = true;
        checkIfAllModelsAreLoaded()
    })
    gltfLoader.load('./models/camera_4_broken.glb', (object) => {
        object.scene.scale.set(2, 2, 2)
        addCamera(object.scene, 20, 239, 1.4)

        objectsAreReady.camera_broken = true;
        checkIfAllModelsAreLoaded()
    })
    function addCamera(mesh, x, z, rotationCoef, reflected) {
        const localCamera = mesh.clone();
        localCamera.position.set(x, 1, z);
        localCamera.rotation.y = Math.PI * rotationCoef
        if (reflected) { localCamera.scale.z = -1 }
        scene.add(localCamera);
    }

    //
    // Turning Sings
    //

    const boxSignHelper = new THREE.Mesh(
        new THREE.BoxGeometry( 1, 2, 1 ),
        new THREE.MeshBasicMaterial( {color: 0x00ff00, opacity: 0, transparent: true} )
    );
    boxSignHelper.position.y = 1

    gltfLoader.load('./models/sign-turn/left.glb', (object) => {
            const localSignMesh = object.scene;
            const localBoxHelper = boxSignHelper.clone();
            signsList.left1.object = new THREE.Group();
            signsList.left1.object.add(localSignMesh, localBoxHelper)
            signsList.left1.object.position.set(82, 1, 100)
            signsList.left1.object.scale.set(4, 4, 4)

            signsList.left2.object = signsList.left1.object.clone()
            signsList.left2.object.position.set(-10, 1, 300)

            scene.add(signsList.left1.object, signsList.left2.object);

            objectsAreReady.sign_left = true;
            checkIfAllModelsAreLoaded()
        }
    )
    gltfLoader.load('./models/sign-turn/right.glb', (object) => {
            const localSignMesh = object.scene;
            const localBoxHelper = boxSignHelper.clone();
            signsList.right1.object = new THREE.Group();
            signsList.right1.object.add(localSignMesh, localBoxHelper)
            signsList.right1.object.position.set(96, 1, 100)
            signsList.right1.object.scale.set(4, 4, 4)

            signsList.right2.object = signsList.right1.object.clone()
            signsList.right2.object.position.set(10, 1, 300)

            scene.add(signsList.right1.object, signsList.right2.object);

            objectsAreReady.sign_right = true;
            checkIfAllModelsAreLoaded()
        }
    )

    /**
     * Wind
     */
    gltfLoader.load('./models/windmill.glb', (object) => {
        object.scene.scale.set(2, 2, 2)
        windmillMixerList.mail = addWindmill(object,13, -64, -0.7, true);
        windmillMixerList.cafe = addWindmill(object,61, 64, 1.2, true);
        windmillMixerList.port = addWindmill(object,-32, 146, -0.1, true);

        objectsAreReady.windmill = true;
        checkIfAllModelsAreLoaded()
    })
    gltfLoader.load('./models/windmill_1.glb', (object) => {
        object.scene.scale.set(2, 2, 2)
        addWindmill(object,48, 197, -0.4,  false);

        objectsAreReady.windmill_broken = true;
        checkIfAllModelsAreLoaded()
    })
    function addWindmill(source, x, z, rotationY, isAnimationExist, debug) {
        const localWindmill = SkeletonUtils.clone(source.scene)
        localWindmill.position.set(x, 1, z)
        localWindmill.rotation.y = rotationY
        scene.add(localWindmill)

        if (debug) {
            gui.add(localWindmill.position, 'x').step(1)
            gui.add(localWindmill.position, 'z').step(1)
            gui.add(localWindmill.rotation, 'y').step(1).step(0.1)
        }

        if (isAnimationExist) {
            const localWindmillMixer = new THREE.AnimationMixer(localWindmill)
            const localWindmillAction = localWindmillMixer.clipAction(source.animations[0])
            localWindmillAction.play();

            return localWindmillMixer
        }
    }

    /**
     * Box
     */
    gltfLoader.load('./models/box_anims.glb', (object) => {
        boxMesh = object.scene
        boxMesh.scale.set(0, 0, 0)
        boxMesh.position.set(34, 5, 219)
        boxMesh.rotation.y = 0.5
        scene.add(boxMesh)

        objectsAreReady.box = true;
        checkIfAllModelsAreLoaded()
    })
}

function addBox () {
    gsap.to(boxMesh.scale, { duration: 1, x: 2, y: 2, z: 2 })
    gsap.to(boxMesh.position, { delay: 1,  duration: 4, x: 25, z: 200 })
    gsap.to(boxMesh.scale, { delay: 5,  duration: 1, x: 0, y: 0, z: 0 })
    gsap.to(boxMesh.position, { delay: 7,  duration: 1, x: 34, z: 219 })
}

function checkIfAllModelsAreLoaded() {
    let allModelsAreReady = true;
    Object.keys(objectsAreReady).forEach(item => {
        if (!objectsAreReady[item]) allModelsAreReady = false
    })
    if (allModelsAreReady) {
        tickFunction();
        loaderElement.classList.add('hide')
    }
}

export {
    addModels, addBox, setTickFunction,
    carGroup, carMixer, carAction,
    locationsList,
    signsList, barier,
    windmillMixerList
}
