import * as THREE from "three";
import * as dat from "lil-gui";
import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader.js";
import * as SkeletonUtils from "three/examples/jsm/utils/SkeletonUtils";
import gsap from "gsap";
import { curvePoints1, curvePoints2 } from "./utils/pointsList";
import { roadCreating, ls, M3, M4 } from "./utils/roadCreating";
import addLight from "./utils/light";
import addDecorations from "./utils/decorations";
import setSizes from "./utils/sizes";
import setRenderer from "./utils/setRenderer";
import setCamera from "./utils/setCamera";
import {
  canvas,
  flashBigElement,
  flashSmallElement,
  infoElement,
  infoOutsideElement,
  simpleFinishElement,
  timeElement,
  weightElement,
  arrowLeft,
  arrowRight,
  navigationParentElement,
  navigationContainerElement,
  continueButtonElement,
  continueButtonSimpleElement,
  finishButtonElement,
  finishButtonSimpleElement,
} from "./utils/getHTMLElements";
import {
  soundIsAllowed,
  boxSound,
  flashSound,
  successSound,
} from "./utils/audio";
import {
  addModels,
  addBox,
  setTickFunction,
  carGroup,
  carMixer,
  carAction,
  locationsList,
  signsList,
  barier,
  windmillMixerList,
} from "./utils/models";

/**
 * Base
 */
// Debug
// const gui = new dat.GUI()
// const debugObject = {}

THREE.ColorManagement.enabled = false;
const scene = new THREE.Scene();

/**
 * Road
 */
let iCar = 50;
let estimatePosition = iCar;
let isCurrentRoad1 = true;
let road1Settings = { t: [], b: [], n: [], points: null };
let road2Settings = { t: [], b: [], n: [], points: null };

roadCreating(road1Settings, curvePoints1, scene);
roadCreating(road2Settings, curvePoints2, scene);

let freezeCar = false;
let freezeCarForward = false;
let freezeCarBackward = false;
addModels(scene);

/**
 * Line
 */
const gltfLoader = new GLTFLoader();
let baseLine;
const lineList = { mail: null, cafe: null, factory: null, port: null };
let lineMixer;

gltfLoader.load("./models/line.glb", (object) => {
  object.scene.scale.set(2, 2, 2);
  baseLine = object;
  scene.traverse((child) => {
    if (
      child instanceof THREE.Mesh &&
      child.material instanceof THREE.MeshStandardMaterial
    ) {
      child.castShadow = false;
      child.receiveShadow = false;
    }
  });
});

function addLine(x, y, z, rotationCoef, type, circle) {
  infoElement.classList.remove(
    "show-time",
    "show-weight",
    "progress-end",
    "show-arrows",
    "show-left-arrow",
    "show-right-arrow",
    "show-without-marker-and-progressbar"
  );
  infoOutsideElement.classList.remove("show");
  freezeCar = true;

  let localLine;
  if (type !== "factory") {
    localLine = SkeletonUtils.clone(baseLine.scene);
    localLine.rotation.y = Math.PI * rotationCoef;
    localLine.position.set(x, y, z);
    scene.add(localLine);

    //Animation
    lineMixer = new THREE.AnimationMixer(localLine);
    lineMixer.timeScale = 1.5;
    const lineAction = lineMixer.clipAction(baseLine.animations[0]);
    if (type === "port") {
      const animDuration = lineAction.getClip().duration;
      lineAction.setEffectiveTimeScale(-1);
      lineAction.time = animDuration;
    }
    lineAction.play();
  } else {
    addBox();
    localLine = true;
  }

  let flashElement;
  if (type === "factory") {
    flashElement = flashSmallElement;
  } else {
    flashElement = flashBigElement;
  }

  const date = new Date();
  const hours = date.getHours() < 10 ? "0" + date.getHours() : date.getHours();
  const min =
    date.getMinutes() < 10 ? "0" + date.getMinutes() : date.getMinutes();
  const sec =
    date.getSeconds() < 10 ? "0" + date.getSeconds() : date.getSeconds();
  const timeString = hours + ":" + min + ":" + sec;
  const mass = 10 + Math.floor(Math.random() * 140);
  feedInfo[type].unloading.push({ time: timeString, mass });
  feedInfo.all.unloading.push({ time: timeString, mass, type });
  feedInfo.all.index = feedInfo.all.unloading.length - 1;
  updateCard();

  setTimeout(() => {
    flashElement.style.display = "block";
  }, 1000);
  setTimeout(() => {
    flashElement.classList.add("show");
    if (soundIsAllowed) flashSound.play();
  }, 1000);
  setTimeout(() => {
    flashElement.classList.remove("show");
  }, 1200);
  setTimeout(() => {
    flashElement.style.display = "none";
    infoElement.classList.add("show");
    infoOutsideElement.classList.add("show");
  }, 1500);
  setTimeout(() => {
    infoElement.classList.add("show-time");
  }, 3000);
  setTimeout(() => {
    if (type !== "factory") {
      lineMixer.timeScale = 0;
    }
    infoElement.classList.add("show-weight");
  }, 4985);
  setTimeout(() => {
    infoElement.classList.add("progress-end");
    repaintCircleInGreen(circle);
    if (soundIsAllowed) successSound.play();
    freezeCar = false;
  }, 5200);

  return localLine;
}

/**
 * Circles
 */
const circlesList = { mail: null, cafe: null, factory: null, port: null };

const geometryCircle = new THREE.CircleGeometry(14, 36);
const materialCircleGreen = new THREE.MeshBasicMaterial({ color: 0x99e64d });
const materialCircleYellow = new THREE.MeshBasicMaterial({ color: 0xf0c47b });
const circleGreen = new THREE.Mesh(geometryCircle, materialCircleGreen);
circleGreen.position.z = -0.01;
const circleYellow = new THREE.Mesh(geometryCircle, materialCircleYellow);
const circleGroup = new THREE.Group();
circleGroup.add(circleGreen, circleYellow);
circleGroup.rotation.x = -Math.PI / 2;

circlesList.mail = circleGroup.clone();
circlesList.mail.position.set(-5.5, 0.1, -50);
circlesList.cafe = circleGroup.clone();
circlesList.cafe.position.set(83, 0.1, 56);
circlesList.factory = circleGroup.clone();
circlesList.factory.position.set(35, 0.1, 221);
circlesList.factory.rotation.z = 0.3;
circlesList.factory.children[1].material =
  circlesList.factory.children[1].material.clone();
circlesList.port = circleGroup.clone();
circlesList.port.position.set(-42, 0.1, 168);
scene.add(
  circlesList.mail,
  circlesList.cafe,
  circlesList.factory,
  circlesList.port
);

// Markers icon
const lightTexture = new THREE.TextureLoader().load("./textures/light512.svg");
lightTexture.magFilter = THREE.NearestFilter;
const handTexture = new THREE.TextureLoader().load("./textures/hand.png");
const geometryCircleMarker = new THREE.CircleGeometry(3, 72);

const materialCircleLight = new THREE.MeshBasicMaterial({ map: lightTexture });
const materialHand = new THREE.MeshBasicMaterial({
  map: handTexture,
  opacity: 0,
  transparent: true,
});

const markersList = { mail: null, cafe: null, port: null, factory: null };

markersList.factory = addMarker(
  geometryCircleMarker,
  materialHand,
  16,
  186,
  0.15
);
markersList.mail = addMarker(geometryCircleMarker, materialHand, -40, -54, 0.9);
markersList.cafe = addMarker(geometryCircleMarker, materialHand, 112, 47, -0.7);
markersList.port = addMarker(
  geometryCircleMarker,
  materialHand,
  -69,
  150,
  0.4,
  true
);

function addMarker(geometry, material, x, z, rotation) {
  const localMesh = new THREE.Mesh(geometry, material);
  localMesh.position.set(x, 18, z);
  localMesh.rotation.y = rotation;

  scene.add(localMesh);
  return localMesh;
}

/**
 * Utils
 */
const sizes = setSizes();
const camera = setCamera(sizes, scene);
const renderer = setRenderer(canvas, sizes);
renderer.shadowMap.autoUpdate = false;
renderer.shadowMap.needsUpdate = true;
addDecorations(scene);
addLight(scene);

/**
 * Scroll
 */
window.addEventListener("wheel", (e) => {
  if (isOrientationMobileHorizontal()) return;
  let calcDelta = Math.round((e.deltaY / sizes.height) * 50);
  triggerDrive(calcDelta);
});

/**
 * Swipe
 */
window.addEventListener("touchstart", handleTouchStart, false);
window.addEventListener("touchmove", handleTouchMove, false);

let coordsY = null;
function handleTouchStart(event) {
  const firstTouch = event.touches[0];
  coordsY = firstTouch.clientY;
  mouse.x = (event.touches[0].clientX / sizes.width) * 2 - 1;
  mouse.y = -(event.touches[0].clientY / sizes.height) * 2 + 1;
}
function handleTouchMove(event) {
  if (isOrientationMobileHorizontal()) return;
  const delta = coordsY - event.touches[0].clientY;
  triggerDrive(Math.round(delta / 10));
}

function isOrientationMobileHorizontal() {
  return camera.aspect > 1 && window.innerWidth <= 1200;
}

/**
 * triggerDrive
 */

function triggerDrive(calcDelta) {
  if (freezeCar || !window.forceStopPageScroll) return;
  if (estimatePosition >= ls - 100 && iCar >= ls - 100 && calcDelta > 0) return;

  if (carGroup) {
    if (Math.abs(estimatePosition - iCar) > 100) return;

    if (calcDelta > 0 && !freezeCarForward) {
      gsap.to(carGroup.children[0].rotation, {
        duration: 0.8,
        y: Math.PI * 0.5,
      });
      changeEstimateCarPosition(calcDelta);
      freezeCarBackward = false;
    }
    if (calcDelta < 0 && !freezeCarBackward) {
      gsap.to(carGroup.children[0].rotation, {
        duration: 0.8,
        y: -Math.PI * 0.5,
      });
      changeEstimateCarPosition(calcDelta);
      freezeCarForward = false;
    }

    if (estimatePosition > ls - 100) {
      estimatePosition = ls - 100;
    }
    if (estimatePosition < 50) {
      estimatePosition = 50;
    }

    if (estimatePosition > 315 && estimatePosition < 375 && calcDelta < 0) {
      estimatePosition = 293;
    }
    if (estimatePosition > 775 && estimatePosition < 850 && calcDelta > 0) {
      estimatePosition = 863;
    }
  }
  if (feedInfo.currentLocation !== "all") {
    infoElement.classList.remove("show");
    infoOutsideElement.classList.remove("show");
  }
  // console.log('estim', estimatePosition)
}
function changeEstimateCarPosition(calcDelta) {
  let previousPosition = estimatePosition;
  estimatePosition += calcDelta;

  stickToLocation(107); // mail
  stickToLocation(233); // cafe
  if (isCurrentRoad1) {
    stickToLocation(518);
  } // port
  else {
    stickToLocation(628);
  } // factory

  function stickToLocation(locationCoords) {
    if (
      previousPosition <= locationCoords &&
      estimatePosition > locationCoords
    ) {
      if (previousPosition !== locationCoords) {
        estimatePosition = locationCoords;
      }
      freezeCar = true;
      setTimeout(() => (freezeCar = false), 300);
    }
    if (
      previousPosition > locationCoords &&
      estimatePosition <= locationCoords
    ) {
      if (estimatePosition !== locationCoords) {
        estimatePosition = locationCoords;
      }
      freezeCar = true;
      setTimeout(() => (freezeCar = false), 300);
    }
  }

  // crossroads
  if (calcDelta > 0 && previousPosition < 293 && estimatePosition >= 293) {
    estimatePosition = 293;
    freezeCarForward = true;
    freezeCar = true;
    setTimeout(() => (freezeCar = false), 300);
  }
  if (calcDelta < 0 && previousPosition > 863 && estimatePosition <= 863) {
    estimatePosition = 863;
    freezeCarBackward = true;
    freezeCar = true;
    setTimeout(() => (freezeCar = false), 300);
  }
}

/**
 * Mouse
 */
const raycaster = new THREE.Raycaster();

let feedInfo = {
  currentLocation: "mail",
  mail: { index: 0, unloading: [] },
  cafe: { index: 0, unloading: [] },
  factory: { index: 0, unloading: [] },
  port: { index: 0, unloading: [] },
  all: { index: 0, unloading: [] },
};
let intersect = {
  location: { mail: null, cafe: null, factory: null, port: null },
  box: { mail: null, cafe: null, factory: null, port: null },
};
let carBelongsToLocation = {
  mail: false,
  cafe: false,
  factory: false,
  port: false,
};

let intersectSignLeft1,
  intersectSignRight1,
  intersectSignLeft2,
  intersectSignRight2;

const mouse = new THREE.Vector2();

window.addEventListener("mousemove", (event) => {
  mouse.x = (event.clientX / window.innerWidth) * 2 - 1;
  mouse.y = -(event.clientY / sizes.height) * 2 + 1;
});

const clickHelper = [
  { type: "mail", linePosition: [-20, -45], rotation: 1.1 },
  { type: "cafe", linePosition: [97, 59], rotation: 0.1 },
  { type: "factory", linePosition: [30, 212], rotation: 0.65 },
  { type: "port", linePosition: [-54, 161], rotation: 0.75 },
];

window.addEventListener("click", (event) => clickOrTouchReaction());
window.addEventListener("pointerup", (event) => clickOrTouchReaction());
function clickOrTouchReaction() {
  clickHelper.forEach((item) => {
    if (intersect.location[item.type] && intersect.location[item.type].length) {
      if (carBelongsToLocation[item.type]) {
        feedInfo.currentLocation = "all";
        if (!lineList[item.type]) {
          if (soundIsAllowed) boxSound.play();
          lineList[item.type] = addLine(
            item.linePosition[0],
            1,
            item.linePosition[1],
            item.rotation,
            item.type,
            circlesList[item.type]
          );
        }
      } else if (feedInfo.all.unloading.length) {
        feedInfo.currentLocation = "all";
        updateCard();
        infoElement.classList.add("show");
        infoElement.classList.add("show-without-marker-and-progressbar");
        infoOutsideElement.classList.add("show");
      }
    }
  });

  // Turn
  if (signsList.left1.intersected || signsList.left2.intersected) {
    isCurrentRoad1 = true;
  }
  if (signsList.right1.intersected || signsList.right2.intersected) {
    isCurrentRoad1 = false;
  }

  if (signsList.left1.intersected || signsList.right1.intersected) {
    freezeCarForward = false;
  }
  if (signsList.left2.intersected || signsList.right2.intersected) {
    freezeCarBackward = false;
  }

  if (
    signsList.left1.intersected ||
    signsList.left2.intersected ||
    signsList.right1.intersected ||
    signsList.right2.intersected
  ) {
    if (iCar < 500) {
      estimatePosition = 410;
    } else {
      estimatePosition = 750;
    }
    setTimeout(() => {
      signsList.left1.intersected = false;
      signsList.right1.intersected = false;
      signsList.left1.intersected = false;
      signsList.right1.intersected = false;
    }, 500);
  }
}

infoOutsideElement.addEventListener("click", () => {
  if (infoElement.classList.contains("progress-end")) {
    infoElement.classList.remove("show");
    infoOutsideElement.classList.remove("show");
  }
  simpleFinishElement.classList.remove("show");
});

arrowLeft.addEventListener("click", () => {
  const currentLocation = feedInfo.currentLocation;
  feedInfo[currentLocation].index--;
  if (feedInfo[currentLocation].index === -1) {
    feedInfo[currentLocation].index =
      feedInfo[currentLocation].unloading.length - 1;
  }
  arrowInteraction();
});

arrowRight.addEventListener("click", () => {
  const currentLocation = feedInfo.currentLocation;
  feedInfo[currentLocation].index++;
  if (
    feedInfo[currentLocation].index ===
    feedInfo[currentLocation].unloading.length
  ) {
    feedInfo[currentLocation].index = 0;
  }
  arrowInteraction();
});
function arrowInteraction() {
  if (!infoElement.classList.contains("progress-end")) {
    infoElement.classList.add("show-time", "show-weight");
  }

  const isFinal = infoElement.classList.contains("show-finish");
  updateCard(isFinal);
}

continueButtonElement.addEventListener("click", () => continueButtonClick());
continueButtonSimpleElement.addEventListener("click", () =>
  continueButtonClick()
);
function continueButtonClick() {
  estimatePosition = 865;
  triggerDrive(-5);
}

finishButtonElement.addEventListener("click", () => finishButtonClick());
finishButtonSimpleElement.addEventListener("click", () => finishButtonClick());
function finishButtonClick() {
  infoElement.classList.remove("show");
  simpleFinishElement.classList.remove("show");

  const mailVisited = feedInfo.mail.unloading.length;
  const cafeVisited = feedInfo.cafe.unloading.length;
  const factoryVisited = feedInfo.factory.unloading.length;
  const portVisited = feedInfo.port.unloading.length;
  if (mailVisited && cafeVisited && factoryVisited && portVisited) {
    barier.action.play();
    freezeCar = true;
    setTimeout(() => {
      estimatePosition = ls - 10;
    }, 2000);
    setTimeout(() => {
      freezeCar = false;
    }, 5000);
  }
}

function updateCard(final) {
  const currentLocation = feedInfo.currentLocation;
  const currentIndex = feedInfo[currentLocation].index;

  infoElement.classList.remove(
    "type-mail",
    "type-cafe",
    "type-factory",
    "type-port",
    "type-all",
    "show-finish",
    "ready"
  );
  infoElement.classList.add(`type-${currentLocation}`);

  timeElement.textContent =
    feedInfo[currentLocation].unloading[currentIndex].time || "";
  weightElement.textContent =
    feedInfo[currentLocation].unloading[currentIndex].mass || "";

  const circleElemHTML = '<div class="navigation-circle"></div>';
  navigationContainerElement.innerHTML = circleElemHTML.repeat(
    feedInfo[currentLocation].unloading.length
  );
  const elementsCount = feedInfo[currentLocation].unloading.length;
  if (elementsCount > 6) {
    stabilizeNavigation(currentIndex, feedInfo.all.unloading.length);
  }
  const listOfCircles = document.querySelectorAll(".navigation-circle");

  listOfCircles[currentIndex].classList.add("selected");
  listOfCircles.forEach((elem, index) => {
    elem.addEventListener("click", () => {
      updateIndex(index, currentLocation);
    });
  });

  infoElement.classList.remove("show-arrows");
  infoElement.classList.remove("show-left-arrow");
  infoElement.classList.remove("show-right-arrow");
  if (feedInfo.all.unloading.length > 1) {
    if (feedInfo.all.index === feedInfo.all.unloading.length - 1) {
      infoElement.classList.add("show-left-arrow");
    } else if (feedInfo.all.index === 0) {
      infoElement.classList.add("show-right-arrow");
    } else {
      infoElement.classList.add("show-arrows");
    }
  }

  if (feedInfo.currentLocation === "all") {
    infoElement.classList.add(
      `type-${feedInfo.all.unloading[currentIndex].type}`
    );
    const mailVisited = feedInfo.mail.unloading.length;
    const cafeVisited = feedInfo.cafe.unloading.length;
    const factoryVisited = feedInfo.factory.unloading.length;
    const portVisited = feedInfo.port.unloading.length;
    if (final) {
      infoElement.classList.add("show-finish");
      if (
        mailVisited &&
        cafeVisited &&
        factoryVisited &&
        portVisited &&
        final
      ) {
        infoElement.classList.add("ready");
        finishButtonElement.innerHTML = "Finish";
      } else {
        finishButtonElement.innerHTML = "Exit";
      }
    }
  }
}

function stabilizeNavigation(index, length) {
  navigationParentElement.classList.add("overflow");
  const delta = length - index - 1;
  const shift = (delta - 6) * 32;
  if (delta > 6) {
    navigationContainerElement.style.transform = "translateX(" + shift + "px)";
  } else {
    navigationContainerElement.style.transform = "translateX(0)";
  }
}

function updateIndex(index, currentLocation) {
  feedInfo[currentLocation].index = index;
  const isFinal = infoElement.classList.contains("show-finish");
  updateCard(isFinal);
}

function openGeneralFeed() {
  if (
    !infoElement.classList.contains("show") &&
    feedInfo.all.unloading.length
  ) {
    infoElement.classList.add("show");
    infoOutsideElement.classList.add("show");
    feedInfo.currentLocation = "all";
    feedInfo.all.index = feedInfo.all.unloading.length - 1;
    updateCard(true);
  }
  if (
    !simpleFinishElement.classList.contains("show") &&
    !feedInfo.all.unloading.length
  ) {
    simpleFinishElement.classList.add("show");
    infoOutsideElement.classList.add("show");
  }
}

function closeGeneralFeed() {
  if (infoElement.classList.contains("show")) {
    infoElement.classList.remove("show");
    infoOutsideElement.classList.remove("show");
  }
  simpleFinishElement.classList.remove("show");
}

/**
 * Animate
 */
const clock = new THREE.Clock();
let previousTime = 0;
let mediateMove = false;

let animationIsGoing = false;
let timeFromLastStop = 0;
let ticksCount = 0;
let delta = { x: 0, z: 0 };

let isFirstVisitFactory = true;

const tick = () => {
  const elapsedTime = clock.getElapsedTime();
  const deltaTime = elapsedTime - previousTime;
  previousTime = elapsedTime;

  // Animate after scroll
  if (carGroup) {
    if (carGroup.position.x === 0 && carGroup.position.z === 0) driving();

    const roundedDelta = Math.round(deltaTime * 100);
    boostOnCrossroads(roundedDelta);
    if (estimatePosition - iCar > 2) {
      driving(roundedDelta);
    }
    if (estimatePosition - iCar < -2) {
      driving(-roundedDelta);
    }
    if (estimatePosition - iCar <= 2 && estimatePosition - iCar >= -2) {
      preparingToStopAnimation(deltaTime);
    }
    mediateMove = !mediateMove;
    cameraControl();
    checkCarInteractions();
  }

  castRay();
  if (
    signsList.left1.object &&
    signsList.right1.object &&
    signsList.left2.object &&
    signsList.right2.object
  ) {
    checkSignHover();
  }

  updateMixers(deltaTime);
  bounceMarkers();

  renderer.render(scene, camera);
  window.requestAnimationFrame(tick);
  ticksCount++;
};
setTickFunction(tick);

function boostOnCrossroads(roundedDelta) {
  if (iCar > 315 && iCar < 370 && estimatePosition === 410) {
    driving(roundedDelta * 5);
  }
  if (iCar > 315 && iCar < 370 && estimatePosition <= 293) {
    driving(-roundedDelta * 5);
  }

  if (iCar > 790 && iCar < 840 && estimatePosition >= 863) {
    driving(roundedDelta * 5);
  }
  if (iCar > 800 && iCar < 840 && estimatePosition === 750) {
    driving(-roundedDelta * 5);
  }
}

function bounceMarkers() {
  for (let key in markersList) {
    if (markersList[key].position.y === 18) {
      gsap.to(markersList[key].position, {
        duration: 1,
        ease: "linear",
        y: 25,
      });
    }
    if (markersList[key].position.y === 25) {
      gsap.to(markersList[key].position, {
        duration: 1,
        ease: "linear",
        y: 18,
      });
    }
  }
}

function updateMixers(deltaTime) {
  if (carMixer) {
    carMixer.update(deltaTime);
  }
  if (lineMixer) {
    lineMixer.update(deltaTime);
  }
  if (barier.mixer && iCar > 800) {
    barier.mixer.update(deltaTime);
  }

  //wind
  if (windmillMixerList.mail) {
    windmillMixerList.mail.update(deltaTime);
  }
  if (windmillMixerList.cafe) {
    windmillMixerList.cafe.update(deltaTime);
  }
  if (windmillMixerList.port) {
    windmillMixerList.port.update(deltaTime);
  }
}

function checkSignHover() {
  intersectSignLeft1 = raycaster.intersectObject(signsList.left1.object);
  intersectSignRight1 = raycaster.intersectObject(signsList.right1.object);
  intersectSignLeft2 = raycaster.intersectObject(signsList.left2.object);
  intersectSignRight2 = raycaster.intersectObject(signsList.right2.object);

  if (intersectSignLeft1.length && freezeCarForward) {
    if (!signsList.left1.intersected) signsList.left1.intersected = true;
  } else {
    if (signsList.left1.intersected) signsList.left1.intersected = false;
  }

  if (intersectSignRight1.length && freezeCarForward) {
    if (!signsList.right1.intersected) signsList.right1.intersected = true;
  } else {
    if (signsList.right1.intersected) signsList.right1.intersected = false;
  }

  if (intersectSignLeft2.length && freezeCarBackward) {
    if (!signsList.left2.intersected) signsList.left2.intersected = true;
  } else {
    if (signsList.left2.intersected) signsList.left2.intersected = false;
  }

  if (intersectSignRight2.length && freezeCarBackward) {
    if (!signsList.right2.intersected) signsList.right2.intersected = true;
  } else {
    if (signsList.right2.intersected) signsList.right2.intersected = false;
  }
  changeSingsSize(
    freezeCarForward,
    signsList.left1.object,
    signsList.right1.object
  );
  changeSingsSize(
    freezeCarBackward,
    signsList.left2.object,
    signsList.right2.object
  );
}

function changeSingsSize(freezePoint, sign1, sign2) {
  if (freezePoint) {
    if (sign1.scale.x === 8) {
      gsap.to(sign1.scale, { duration: 1, x: 6, y: 6, z: 6 });
      gsap.to(sign2.scale, { duration: 1, x: 6, y: 6, z: 6 });
    }
    if (sign1.scale.x === 6 || sign1.scale.x === 4) {
      gsap.to(sign1.scale, { duration: 1, x: 8, y: 8, z: 8 });
      gsap.to(sign2.scale, { duration: 1, x: 8, y: 8, z: 8 });
    }
  } else {
    if (!gsap.isTweening(sign1) && sign1.scale.x !== 4) {
      gsap.to(sign1.scale, { duration: 1, x: 4, y: 4, z: 4 });
      gsap.to(sign2.scale, { duration: 1, x: 4, y: 4, z: 4 });
    }
  }
}

function driving(deltaCarPosition = 0) {
  let slowCoef = 4;
  if (iCar < 160) slowCoef = 10;
  if (deltaCarPosition !== 0) tryToStartCarAnimation();

  let currentRoadSettings;
  if (isCurrentRoad1) {
    currentRoadSettings = road1Settings;
  } else {
    currentRoadSettings = road2Settings;
  }

  let coef = 0.18;

  M3.set(
    currentRoadSettings.t[iCar].x,
    currentRoadSettings.b[iCar].x,
    currentRoadSettings.n[iCar].x,
    currentRoadSettings.t[iCar].y,
    currentRoadSettings.b[iCar].y,
    currentRoadSettings.n[iCar].y,
    currentRoadSettings.t[iCar].z,
    currentRoadSettings.b[iCar].z,
    currentRoadSettings.n[iCar].z
  );
  M4.setFromMatrix3(M3);
  carGroup.setRotationFromMatrix(M4);

  if (ticksCount % slowCoef === 0 && carGroup.position.x !== 0) {
    iCar += deltaCarPosition;
    delta.x =
      currentRoadSettings.points[iCar].x +
      coef * currentRoadSettings.n[iCar].x -
      carGroup.position.x;
    delta.z =
      currentRoadSettings.points[iCar].z +
      coef * currentRoadSettings.n[iCar].z -
      carGroup.position.z;
  } else {
    if (carGroup.position.x === 0) {
      carGroup.position.set(
        currentRoadSettings.points[iCar].x +
          coef * currentRoadSettings.n[iCar].x,
        1,
        currentRoadSettings.points[iCar].z +
          coef * currentRoadSettings.n[iCar].z
      );
    } else {
      carGroup.position.x += delta.x / slowCoef;
      carGroup.position.z += delta.z / slowCoef;
    }
  }
  if (iCar > ls - 110 && iCar < ls - 90 && !freezeCar) {
    openGeneralFeed();
  } else closeGeneralFeed();
  // console.log('iCar', iCar, estimatePosition)
}
function tryToStartCarAnimation() {
  if (!animationIsGoing) {
    animationIsGoing = true;
    carAction.loop = Infinity;
    carAction.reset().play();
  }
}

function preparingToStopAnimation(deltaTime) {
  if (animationIsGoing) {
    timeFromLastStop += deltaTime;
  }

  if (timeFromLastStop > 1) {
    animationIsGoing = false;
    timeFromLastStop = 0;
    carAction.clampWhenFinished = true;
    carAction.loop = THREE.LoopOnce;
  }
}

function castRay() {
  raycaster.setFromCamera(mouse, camera);

  if (locationsList.mail) {
    intersect.location.mail = raycaster.intersectObject(locationsList.mail);
  }
  if (locationsList.cafe) {
    intersect.location.cafe = raycaster.intersectObject(locationsList.cafe);
  }
  if (locationsList.factory) {
    intersect.location.factory = raycaster.intersectObject(
      locationsList.factory
    );
  }
  if (locationsList.port) {
    intersect.location.port = raycaster.intersectObject(locationsList.port);
  }
}

function cameraControl() {
  if (carGroup.position.z > 330) return;
  camera.position.z = carGroup.position.z + 35;
  camera.position.x = carGroup.position.x;
}

function checkCarInteractions() {
  checkCarInteraction("mail", markersList.mail);
  checkCarInteraction("cafe", markersList.cafe);
  checkCarInteraction("factory", markersList.factory);
  checkCarInteraction("port", markersList.port);
}
function checkCarInteraction(type, marker) {
  const isRightPosition =
    carGroup.position.z > circlesList[type].position.z - 10 &&
    carGroup.position.z < circlesList[type].position.z + 10 &&
    carGroup.position.x > circlesList[type].position.x - 10 &&
    carGroup.position.x < circlesList[type].position.x + 10;
  if (isRightPosition) {
    if (circlesList[type].scale.x === 1) {
      carBelongsToLocation[type] = true;
      gsap.to(circlesList[type].scale, { duration: 1, x: 1.5, y: 1.5, z: 1.5 });
      if (marker) {
        gsap.to(marker.material, { duration: 1, opacity: 1 });
      }
    }
    if (lineList[type] && marker && marker.material.opacity === 1) {
      gsap.to(marker.material, { duration: 1, opacity: 0 });
    }

    if (circlesList[type] === circlesList.factory && isFirstVisitFactory) {
      isFirstVisitFactory = false;
      const colorYellow = 0xf0c47b;
      const colorGrey = 0xa08252;
      let material = circlesList.factory.children[1].material;

      setTimeout(() => {
        material.color.set(colorGrey);
      }, 1000);
      setTimeout(() => {
        material.color.set(colorYellow);
      }, 1500);
      setTimeout(() => {
        material.color.set(colorGrey);
      }, 2000);
      setTimeout(() => {
        material.color.set(colorYellow);
      }, 2500);
      setTimeout(() => {
        material.color.set(colorGrey);
      }, 2700);
      setTimeout(() => {
        material.color.set(colorYellow);
      }, 2800);
      setTimeout(() => {
        material.color.set(colorGrey);
      }, 2900);
      setTimeout(() => {
        material.color.set(colorYellow);
      }, 3000);
      setTimeout(() => {
        material.color.set(colorGrey);
      }, 3100);
      setTimeout(() => {
        material.color.set(colorYellow);
      }, 3200);
      setTimeout(() => {
        circlesList.factory.children[1].material = materialCircleLight;
      }, 3300);
    }
  } else {
    if (circlesList[type].scale.x === 1.5) {
      carBelongsToLocation[type] = false;
      gsap.to(circlesList[type].scale, { duration: 1, x: 1, y: 1, z: 1 });
      if (marker) {
        gsap.to(marker.material, { duration: 1, opacity: 0 });
      }
    }
    if (lineList[type]) {
      scene.remove(lineList[type]);
      lineList[type] = null;
    }
  }
}

function repaintCircleInGreen(circleMash) {
  gsap.to(circleMash.children[1].scale, {
    duration: 1,
    x: 0.9,
    y: 0.9,
    z: 0.9,
  });
}
