import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader.js';
const gltfLoader = new GLTFLoader()

export default function addDecorations(scene) {
    gltfLoader.load('./models/decorations/cloud.glb', (object) => {
        let basicCloud = object.scene;
        for (let i = 0; i < 40; i++) {
            createCloud(basicCloud)
        }
    })
    gltfLoader.load('./models/decorations/tree_2.glb', (object) => {
        let basicThree = object.scene;
        addTreeAndStone(basicThree, 0, -200)
        addTreeAndStone(basicThree, 30, -150)
        addTreeAndStone(basicThree, 0, -100)
        addTreeAndStone(basicThree, 40, -50)
        addTreeAndStone(basicThree, 100, 0)
        addTreeAndStone(basicThree, 60, 50)
        addTreeAndStone(basicThree, 50, 100)
        addTreeAndStone(basicThree, 100, 150)
        addTreeAndStone(basicThree, 100, 200)
        addTreeAndStone(basicThree, 0, 250)
        addTreeAndStone(basicThree, -10, 285)
        addTreeAndStone(basicThree, 10, 340)
    })
    gltfLoader.load('./models/decorations/tree_5.glb', (object) => {
        let basicThree = object.scene;
        addTreeAndStone(basicThree, -30, -160)
        addTreeAndStone(basicThree, -5, -130)
        addTreeAndStone(basicThree, -15, -95)
        addTreeAndStone(basicThree, -0, -40)
        addTreeAndStone(basicThree, 20, 10)
        addTreeAndStone(basicThree, 95, 10)
        addTreeAndStone(basicThree, 120, 75)
        addTreeAndStone(basicThree, 60, 140)
        addTreeAndStone(basicThree, -60, 175)
        addTreeAndStone(basicThree, -20, 200)
        addTreeAndStone(basicThree, -60, 220)
        addTreeAndStone(basicThree, -60, 260)
        addTreeAndStone(basicThree, -10, 290)
        addTreeAndStone(basicThree, -20, 320)
    })
    gltfLoader.load('./models/decorations/tree_10.glb', (object) => {
        let basicThree = object.scene;
        addTreeAndStone(basicThree, -50, -180)
        addTreeAndStone(basicThree, 10, -140)
        addTreeAndStone(basicThree, -55, -110)
        addTreeAndStone(basicThree, 10, -70)
        addTreeAndStone(basicThree, 50, -10)
        addTreeAndStone(basicThree, 30, 40)
        addTreeAndStone(basicThree, 95, 60) // near cafe
        addTreeAndStone(basicThree, 10, 120)
        addTreeAndStone(basicThree, -30, 170)
        addTreeAndStone(basicThree, 60, 180)
        addTreeAndStone(basicThree, -90, 200)
        addTreeAndStone(basicThree, 45, 225)
        addTreeAndStone(basicThree, 5, 280)

    })
    gltfLoader.load('./models/decorations/stone_1.glb', (object) => {
        let basicThree = object.scene;
        addTreeAndStone(basicThree, 10, -180)
        addTreeAndStone(basicThree, 10, -160)
        addTreeAndStone(basicThree, -5, -130)
        addTreeAndStone(basicThree, -20, -100)
        addTreeAndStone(basicThree, 20, -50)
        addTreeAndStone(basicThree, 20, -20)
        addTreeAndStone(basicThree, 10, 20)
        addTreeAndStone(basicThree, 100, 100)
        addTreeAndStone(basicThree, 110, 108)
        addTreeAndStone(basicThree, 104, 110)
        addTreeAndStone(basicThree, 80, 140)
        addTreeAndStone(basicThree, 80, 160)
        addTreeAndStone(basicThree, 50, 180)
        addTreeAndStone(basicThree, 70, 230)
        addTreeAndStone(basicThree, -20, 270)
        addTreeAndStone(basicThree, 0, 340)
    })
    gltfLoader.load('./models/decorations/stone_2.glb', (object) => {
        let basicThree = object.scene;
        addTreeAndStone(basicThree, 5, -145)
        addTreeAndStone(basicThree, -25, -135)
        addTreeAndStone(basicThree, 0, -80)
        addTreeAndStone(basicThree, 0, 0)
        addTreeAndStone(basicThree, 35, 20)
        addTreeAndStone(basicThree, 86, 115)
        addTreeAndStone(basicThree, 60, 130)
        addTreeAndStone(basicThree, 110, 170)
        addTreeAndStone(basicThree, 50, 210)
        addTreeAndStone(basicThree, -10, 255)
        addTreeAndStone(basicThree, -55, 255)
        addTreeAndStone(basicThree, -10, 330)
    })

    function createCloud(basicMesh) {
        let localCloud = basicMesh.clone()
        let xRand = (Math.floor(Math.random() * 30) - 10) * 10;
        let zRand = (Math.floor(Math.random() * 80) - 10) * 10;
        localCloud.position.set(xRand, 20, zRand);
        scene.add(localCloud)
    }

    function addTreeAndStone(basicMesh, x, z) {
        let localMesh = basicMesh.clone()
        localMesh.position.set(x, 0, z);
        scene.add(localMesh)
    }
}
