export default function setSizes() {
  function setWidth() {
    return window.innerWidth;
  }

  const sizes = {
    width: setWidth(),
    height: window.innerHeight,
  };

  window.addEventListener("resize", () => {
    sizes.width = setWidth();
    sizes.height = window.innerHeight;
  });

  return sizes;
}
