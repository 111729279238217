import * as THREE from "three";
import * as dat from "lil-gui";
// const gui = new dat.GUI()

export default function setCamera(sizes, scene) {
    let fov = 75;
    if (sizes.width <= 1280) {
        fov = 115
    }

    const camera = new THREE.PerspectiveCamera(fov, sizes.width / sizes.height, 0.1, 300)
    camera.position.set(-8.18, 35, -130.2)
    camera.rotation.set(-0.68, 0.03, 0.02)

    window.addEventListener('resize', () => {
        camera.aspect = sizes.width / sizes.height
        camera.updateProjectionMatrix()
    })

    // controlling camera for screens
    // gui.add(camera.position, 'x').step(1).min(-150).max(150);
    // gui.add(camera.position, 'z').step(1).min(-200).max(500);
    // gui.add(camera.position, 'y').step(1).min(0).max(100);
    // gui.add(camera.rotation, 'x').step(0.1).min(-3).max(3);
    // gui.add(camera.rotation, 'x').step(0.1).min(-3).max(3);
    // gui.add(camera.rotation, 'y').step(0.1).min(-3).max(3);
    // gui.add(camera.rotation, 'z').step(0.1).min(-3).max(3);

    scene.add(camera)

    return camera
}
